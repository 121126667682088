import styled from 'styled-components'
import Arrow from 'assets/vectors/paginator-arrow-head.svg'

export const Button = styled.button`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;

  &:disabled {
    background: ${({ theme }) => theme.colors.neutralRegular};

    &:hover {
      background: ${({ theme }) => theme.colors.neutralRegular};
    }
  }

  ${({ theme, primary }) =>
    primary
      ? `
        background: ${theme.colors.quaternaryMedium};

        &:hover {
          background: ${theme.colors.tertiaryLight};
        }
      `
      : `
        background: ${theme.colors.primaryLight};
        
        &:hover {
          background: ${theme.colors.primaryMedium};
        }
      `}
`

export const ArrowIcon = styled(Arrow)`
  ${({ turnLeft }) => turnLeft};

  path {
    stroke: ${({ theme }) => theme.colors.backgroundLight};
  }
`
