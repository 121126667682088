import React from 'react'

import { Button, ArrowIcon } from './style'

const Paginator = ({ primary, turnLeft, className }) => (
  <Button
    primary={primary}
    className={className}
    aria-label={
      turnLeft ? 'mostrar conteúdo anterior' : 'mostrar próximo conteúdo'
    }
  >
    <ArrowIcon css={{ transform: turnLeft }} />
  </Button>
)

export default Paginator
