import React from 'react'
import { Swiper } from 'swiper/react'
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/components/navigation/navigation.min.css'

import Paginator from 'components/Paginator'

SwiperCore.use([Navigation])

const Carousel = ({
  spaceBetweenCards,
  primaryButton,
  slidesPerView,
  loop,
  prevButtonClass,
  nextButtonClass,
  children,
}) => (
  <>
    <Swiper
      loop={loop || false}
      spaceBetween={spaceBetweenCards}
      slidesPerView={slidesPerView || 'auto'}
      navigation={{
        nextEl: `.swiper-button-next-${nextButtonClass}`,
        prevEl: `.swiper-button-prev-${prevButtonClass}`,
      }}
    >
      {children}
    </Swiper>
    <Paginator
      className={`swiper-button-next-${nextButtonClass}`}
      primary={primaryButton}
    />
    <Paginator
      className={`swiper-button-prev-${prevButtonClass}`}
      primary={primaryButton}
      turnLeft="rotate(180deg)"
    />
  </>
)

export default Carousel
